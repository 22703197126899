<template>
  <div class="fluid-container">
    <div class="footer">
      <!-- <a href="https://www.producthunt.com/posts/clipsaver?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-clipsaver" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=363216&theme=neutral" alt="ClipSaver - Save&#0032;past&#0032;and&#0032;discover&#0032;new&#0032;haircuts | Product Hunt" style="width: 250px; height: 54px;" width="250" height="54" /></a> -->
      <a class="link" target="_blank" href="mailto:contact@weekof.app">contact@weekof.app</a>
      &copy; 2023 WeekOf
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
.fluid-container {
  /* position: fixabsoed; */
  margin-top: 80px;
  color: #fff;
  position: relative;
  bottom: 0;
  width: 100%;
  /* height: 2.5rem;     */
}

.footer,
.link {
  display: block;
  margin: 10px;
  font-weight: 400;
  text-decoration: none;
  color: #4b5563;
}

.link:hover {
  color: #fb923c;
}
</style>
