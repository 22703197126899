<template>
  <div class="hello">
    <!-- <div class="mb-4 header">
      <h1>Welcome to</h1>
      <h1 :style="{ color: color }">&#160;WeekOv</h1>
    </div> -->
    <p class="m-4">
      Find out which of your friends are free to hang out. The week of.
    </p>
    <!-- <p class="m-4">Live in Beta.</p> -->

    <div class="form">
      <a class="m-4" href="https://apps.apple.com/app/id6445856722">
        <img src="../assets/app.svg" alt="app store" class="appImg" />
      </a>
      <!-- </div>
    <div class="form mt-4"> 

      <a href='https://play.google.com/store/apps/details?id=com.clipsaver&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' class="appImg" src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
    </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  computed: {
    color() {
      return "rgb(255,140,0)";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.appImg {
  width: 200px;
}

.form:hover {
  opacity: 0.8;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #fb923c;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.form {
  /* max-width: 50%; */
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 600px) {
  .form {
    max-width: 80%;
  }
}
</style>
