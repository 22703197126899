<template>
  <div class="hello">
    <h1>We're sorry</h1>
    <p>
        That page doesn't exist. 
    </p>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage',
}
</script>

