<template>
  <div id="app">
      <img class="logo mt-4" alt="Vue logo" src="./assets/logo.png">
    <div id="nav">
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/privacy">Privacy</router-link> -->
    </div>
    <router-view/>
    <FooterComponent></FooterComponent>
    
  </div>
</template>

<script>
import FooterComponent from "@/components/FooterComponent";
export default {
  name: 'App',
  components: {
    FooterComponent
  }
}
</script>
<style>


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 20px;
  color: #4b5563;
  height: 100%;
}

html, body{
    height: 100%;
}

#nav {
  padding: 10px;
}

#nav a {
  font-weight: bold;
  color: #4b5563;
}

#nav a.router-link-exact-active {
  color: #fb923c;
}



.logo{
  max-width: 5%;
}

@media only screen and (max-width: 600px) {
  .logo{ 
     max-width: 20%;
  }
}
</style>
