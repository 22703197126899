import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
// import VueGtag from "vue-gtag";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(VueRouter)



// Vue.use(VueGtag, {
//   config: { id: "G-J8BHRZJWR4" }
// }, router);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
